<template>
  <div>
    <a-button type="primary" size="small" @click="addText">新增</a-button>
    <a-modal
      :visible="visible"
      title="新增收支"
      @cancel="cancel"
      :footer="null"
      width="800px"
    >
      <a-form
        :form="form"
        :colon="false"
        :label-col="{ span: 8 }"
        :wrapper-col="{ span: 14 }"
        @submit="handleSubmit"
      >
        <a-row>
          <a-col :span="12">
            <a-form-item label="实际发生时间">
              <a-date-picker
                style="width: 100%"
                v-decorator="[
                  'operateDate',
                  {
                    rules: [{ required: true, message: '请选择！' }],
                  },
                ]"
              />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="金额(元)">
              <a-input-number
                :formatter="$inputNumberFormat"
                style="width: 100%"
                v-decorator="[
                  'receivedAmount',
                  {
                    rules: [{ required: true, message: '请输入！' }],
                  },
                ]"
              />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="费用性质">
              <a-select
                v-decorator="[
                  'costNature',
                  {
                    rules: [{ required: true, message: '请选择！' }],
                  },
                ]"
              >
                <a-select-option
                  v-for="item in costNatureList"
                  :key="item.value"
                  :value="item.value"
                  >{{ item.name }}</a-select-option
                >
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="流水来源">
              <a-input v-decorator="['waterSource']" />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="办理人">
              <EmployeeSelector
                @change="
                  (arr) => {
                    if (arr.length) {
                      user = arr[0];
                    } else {
                      user = {};
                    }
                  }
                "
                :value="user.name ? [user] : []"
              >
                <a-button block style="text-align: left">
                  <div v-if="user.name">
                    {{ user.name }}
                  </div>
                  <div v-else>&nbsp;</div>
                </a-button>
              </EmployeeSelector>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="分包性质">
              <a-radio-group v-decorator="['subNature']">
                <a-radio value="0"> 设计类-非必要分包 </a-radio>
                <a-radio value="1"> 设计类-必要分包 </a-radio>
                <a-radio value="2"> 总承包类分包 </a-radio>
              </a-radio-group>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="分包科目">
              <a-input v-decorator="['subSubject']" />
            </a-form-item>
          </a-col>
          <a-col :span="24">
            <a-form-item
              label="备注"
              :label-col="{ span: 4 }"
              :wrapper-col="{ span: 19 }"
            >
              <a-textarea
                :auto-size="{ minRows: 3 }"
                v-decorator="['remarks']"
              />
            </a-form-item>
          </a-col>
        </a-row>

        <div class="right">
          <a-space>
            <a-button @click="cancel">取消</a-button>
            <a-button type="primary" html-type="submit"> 保存 </a-button>
          </a-space>
        </div>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
import { add } from "@/api/project/income-expenditure";
import { mapGetters } from "vuex";
import EmployeeSelector from "@/components/employee-selector";
export default {
  components: {
    EmployeeSelector,
  },

  props: {
    projectId: {
      type: String,
    },
    refreshData: {
      type: Function,
    },
  },

  data() {
    return {
      visible: false,
      form: this.$form.createForm(this),
      user: {},
    };
  },

  computed: {
    ...mapGetters("setting", ["findDataDict"]),
    costNatureList() {
      return this.findDataDict("costNature");
    },
  },

  methods: {
    addText() {
      this.visible = true;
    },
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          console.log("values", values);
          add({
            projectId: this.projectId,
            ...values,
            operateDate: values.operateDate.format("YYYY-MM-DD"),
            operator: this.user.name,
            operatorId: this.user.userId,
          }).then(() => {
            this.form.resetFields();
            this.cancel();
            this.refreshData();
          });
        }
      });
    },
    cancel() {
      this.visible = false;
    },
  },
};
</script>