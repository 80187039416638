<template>
  <div>
    <a href="#" @click.prevent="editText">编辑</a>

    <a-modal
      :visible="visible"
      title="编辑收支"
      @cancel="cancel"
      :footer="null"
      width="800px"
    >
      <a-form
        :form="form"
        :colon="false"
        :label-col="{ span: 8 }"
        :wrapper-col="{ span: 14 }"
        @submit="handleSubmit"
      >
        <a-row>
          <a-col :span="12">
            <a-form-item label="实际发生时间">
              <a-date-picker
                style="width: 100%"
                v-decorator="[
                  'operateDate',
                  {
                    initialValue: formValue.operateDate,
                    rules: [{ required: true, message: '请选择！' }],
                  },
                ]"
              />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="金额(元)">
              <a-input-number
                :formatter="$inputNumberFormat"
                style="width: 100%"
                v-decorator="[
                  'receivedAmount',
                  {
                    initialValue: formValue.receivedAmount,
                    rules: [{ required: true, message: '请输入！' }],
                  },
                ]"
              />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="费用性质">
              <a-select
                v-decorator="[
                  'costNature',
                  {
                    initialValue: formValue.costNature,
                    rules: [{ required: true, message: '请选择！' }],
                  },
                ]"
              >
                <a-select-option
                  v-for="item in costNatureList"
                  :key="item.value"
                  :value="item.value"
                  >{{ item.name }}</a-select-option
                >
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="流水来源">
              <a-input
                v-decorator="[
                  'waterSource',
                  {
                    initialValue: formValue.waterSource,
                  },
                ]"
              />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="办理人">
              <EmployeeSelector
                @change="
                  (arr) => {
                    if (arr.length) {
                      user = arr[0];
                    } else {
                      user = {};
                    }
                  }
                "
                :value="user.name ? [user] : []"
              >
                <a-button block style="text-align: left">
                  <div v-if="user.name">
                    {{ user.name }}
                  </div>
                  <div v-else>&nbsp;</div>
                </a-button>
              </EmployeeSelector>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="分包性质">
              <a-radio-group
                v-decorator="[
                  'subNature',
                  {
                    initialValue: formValue.subNature,
                  },
                ]"
              >
                <a-radio value="0"> 设计类-非必要分包 </a-radio>
                <a-radio value="1"> 设计类-必要分包 </a-radio>
                <a-radio value="2"> 总承包类分包 </a-radio>
              </a-radio-group>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="分包科目">
              <a-input
                v-decorator="[
                  'subSubject',
                  {
                    initialValue: formValue.subSubject,
                  },
                ]"
              />
            </a-form-item>
          </a-col>
          <a-col :span="24">
            <a-form-item
              label="备注"
              :label-col="{ span: 4 }"
              :wrapper-col="{ span: 19 }"
            >
              <a-textarea
                :auto-size="{ minRows: 3 }"
                v-decorator="[
                  'remarks',
                  {
                    initialValue: formValue.remarks,
                  },
                ]"
              />
            </a-form-item>
          </a-col>
        </a-row>

        <div class="right">
          <a-space>
            <a-button @click="cancel">取消</a-button>
            <a-button type="primary" html-type="submit"> 保存 </a-button>
          </a-space>
        </div>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
import { edit } from "@/api/project/income-expenditure";
import { mapGetters } from "vuex";
import EmployeeSelector from "@/components/employee-selector";
import moment from "moment";
export default {
  components: {
    EmployeeSelector,
  },

  props: {
    projectId: {
      type: String,
    },
    text: {
      type: Object,
      default: () => ({}),
    },
    refreshData: {
      type: Function,
    },
  },

  data() {
    return {
      form: this.$form.createForm(this),
      user: {},
      visible: false,
    };
  },

  computed: {
    ...mapGetters("setting", ["findDataDict"]),
    costNatureList() {
      return this.findDataDict("costNature");
    },

    formValue() {
      return {
        ...this.text,
        operateDate: moment(this.text.operateDate),
      };
    },
  },

  watch: {
    visible(newValue) {
      if (newValue) {
        this.setValue();
      }
    },
  },

  mounted() {},

  methods: {
    editText() {
      this.visible = true;
    },

    setValue() {
      const { operator, operatorId } = this.text;

      this.user = {
        name: operator,
        userId: operatorId,
      };
    },

    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          console.log("values", values);
          edit({
            ...this.text,
            projectId: this.projectId,
            ...values,
            operateDate: values.operateDate.format("YYYY-MM-DD"),
            operator: this.user.name,
            operatorId: this.user.userId,
          }).then(() => {
            this.form.resetFields();
            this.cancel();
            this.refreshData();
          });
        }
      });
    },
    cancel() {
      this.visible = false;
    },
  },
};
</script>