<template>
  <div>
    <div class="pane">
      <a-breadcrumb class="breadcrumb">
        <a-breadcrumb-item>
          <router-link to="/produce/progress">进度分配</router-link>
        </a-breadcrumb-item>
        <a-breadcrumb-item>收支详情</a-breadcrumb-item>
      </a-breadcrumb>
      <div style="font-size: 20px">{{ name }}</div>
    </div>

    <Project
      :loading="loading"
      :list="list"
      :income="income"
      :expenditure="expenditure"
      :id="id"
      :getList="getList"
    />
    <Department
      :loading="loading"
      :list="departmentList"
      :income="income"
      :expenditure="expenditure"
      :id="id"
      :getList="getList"
    />
  </div>
</template>

<script>
import { fetchDetail } from "@/api/project/income-expenditure";
import Project from "./components/project";
import Department from "./components/department";
export default {
  components: {
    Project,
    Department,
  },

  data() {
    return {
      loading: false,
      list: [],
      name: "",
      income: 0,
      expenditure: 0,
      id: null,

      departmentList: [],
    };
  },

  mounted() {
    const { query } = this.$route;
    const { id } = query || {};
    if (id) {
      this.id = id;
      this.getList();
    }
  },

  methods: {
    getList() {
      this.loading = true;
      fetchDetail({
        id: this.id,
      })
        .then((res) => {
          if (res) {
            this.name = res.name;
          }
          if (res && Array.isArray(res.paymentList)) {
            this.list = Object.freeze(res.paymentList);

            let income = 0;
            let expenditure = 0;
            res.paymentList.forEach((item) => {
              if (item.costNature === "income") {
                income += item.receivedAmount;
              }
              if (item.costNature === "expenditure") {
                expenditure += item.receivedAmount;
              }
            });

            this.income = Math.abs(income);
            this.expenditure = Math.abs(expenditure);
          }

          if (res && Array.isArray(res.paymentDeptList)) {
            this.departmentList = res.paymentDeptList;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
<style lang="less" scoped>
.pane {
  background-color: #fff;
  padding: 16px 0;
  padding-left: 24px;
}
.breadcrumb {
  margin-bottom: 12px;
}
</style>