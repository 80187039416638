import request from '../request'

// 查询项目收支记录表和部门比例表
export function fetchDetail(params) {
    return request({
        url: `/project-service/payment/queryByProjectId/${params.id}`,
    })
}

// 增加项目收支
export function add(data) {
    return request({
        url: 'project-service/payment/add',
        method: 'post',
        data
    })
}
// 修改项目收支
export function edit(data) {
    return request({
        url: 'project-service/payment/update',
        method: 'post',
        data
    })
}

// 删除项目收支
export function remove(data) {
    return request({
        url: '/project-service/payment/del',
        method: 'post',
        data,
    })
}

// 增加项目收支部门汇总
export function addDepartment(data) {
    return request({
        url: 'project-service/payment/addDept',
        method: 'post',
        data
    })
}
// 修改项目收支部门汇总
export function editDepartment(data) {
    return request({
        url: 'project-service/payment/updateDept',
        method: 'post',
        data
    })
}

// 删除项目收支部门汇总
export function removeDepartment(data) {
    return request({
        url: '/project-service/payment/delDept',
        method: 'post',
        data,
    })
}