<template>
  <div>
    <div class="container container2">
      <div class="header between">
        <div class="title">部门汇总</div>
        <div v-if="$getPermission($route.path + '/add')">
          <Add
            :projectId="id"
            :income="income"
            :expenditure="expenditure"
            :refreshData="getList"
          />
        </div>
      </div>

      <a-table
        bordered
        :data-source="list"
        :loading="loading"
        :pagination="false"
      >
        <a-table-column title="部门名称">
          <template slot-scope="text">
            {{ text.deptName }}
          </template>
        </a-table-column>

        <a-table-column title="比例" align="right">
          <template slot-scope="text"> {{ text.ratio }}% </template>
        </a-table-column>

        <a-table-column title="收入汇总(元)" align="right">
          <template slot-scope="text">
            <span v-if="text.income > 0" style="color: #1890ff">
              {{ text.income }}
            </span>
            <span class="danger" v-else>
              {{ text.income }}
            </span>
          </template>
        </a-table-column>

        <a-table-column title="支出汇总(元)" align="right">
          <template slot-scope="text">
            <span v-if="text.expenditure > 0" style="color: #1890ff">
              {{ text.expenditure }}
            </span>
            <span class="danger" v-else>
              {{ text.expenditure }}
            </span>
          </template>
        </a-table-column>

        <a-table-column align="right" width="120px" fixed="right">
          <div style="padding-right: 7px" slot="title">操作</div>
          <template slot-scope="text">
            <a-space>
              <Edit
                v-if="$getPermission($route.path + '/edit-proportion')"
                :text="text"
                :income="income"
                :expenditure="expenditure"
                :projectId="id"
                :refreshData="getList"
              />

              <a
                href="#"
                class="danger"
                v-if="$getPermission($route.path + '/delete')"
                @click.prevent="removeText(text)"
                >删除</a
              >
            </a-space>
          </template>
        </a-table-column>
      </a-table>
    </div>
  </div>
</template>

<script>
import watermark from "@/mixins/watermark";
import { removeDepartment } from "@/api/project/income-expenditure";

import Add from "./add.vue";
import Edit from "./edit.vue";
export default {
  mixins: [watermark],
  props: ["loading", "list", "name", "income", "expenditure", "id", "getList"],

  components: { Add, Edit },

  mounted() {
    // 设置水印
    const container2 = document.querySelector(".container2");
    if (container2) {
      this.setWatermark(container2);
    }
  },

  methods: {
    removeText(text) {
      const that = this;

      this.$confirm({
        title: "确认要删除这条部门汇总记录吗？",
        onOk() {
          removeDepartment({
            id: text.id,
          }).then(() => {
            that.getList();
          });
        },
      });
    },
  },
};
</script>
<style lang="less" scoped>
.container {
  background-color: #fff;
  padding: 8px;

  .header {
    margin-bottom: 8px;
    padding-bottom: 8px;
    border-bottom: 1px solid #f0f0f0;

    .title {
      font-size: 16px;
    }
  }
}
</style>