<template>
  <div>
    <a-button type="primary" slot="extra" size="small" @click="addText"
      >新增</a-button
    >
    <a-modal
      :visible="visible"
      title="新增部门汇总"
      @cancel="cancel"
      :footer="null"
    >
      <a-form
        :form="form"
        :colon="false"
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 14 }"
        @submit="handleSubmit"
      >
        <a-form-item label="部门">
          <a-select
            show-search
            :filter-option="$selectFilterOption"
            v-decorator="[
              'deptId',
              { rules: [{ required: true, message: '请选择！' }] },
            ]"
            style="width: 100%"
            placeholder=""
          >
            <a-select-option
              v-for="item in organizationList"
              :key="item.id"
              :value="item.id"
              >{{ item.uniqueName }}</a-select-option
            >
          </a-select>
        </a-form-item>

        <a-form-item label="比例">
          <a-input-number
            style="width: 100%"
            :min="0"
            :max="100"
            :formatter="$ratioFormatter"
            v-decorator="[
              'ratio',
              {
                rules: [{ required: true, message: '请输入！' }],
              },
            ]"
          />
        </a-form-item>

        <a-form-item label="收入汇总">
          {{
            form.getFieldValue("ratio") > 0
              ? (income * (form.getFieldValue("ratio") * 0.01)).toFixed(2) +
                " 元"
              : 0
          }}
        </a-form-item>
        <a-form-item label="支出汇总">
          {{
            form.getFieldValue("ratio") > 0
              ? (expenditure * (form.getFieldValue("ratio") * 0.01)).toFixed(
                  2
                ) + " 元"
              : 0
          }}
        </a-form-item>

        <div class="right">
          <a-space>
            <a-button @click="cancel">取消</a-button>
            <a-button type="primary" html-type="submit"> 保存 </a-button>
          </a-space>
        </div>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
import organization from "@/mixins/organization";
import { addDepartment } from "@/api/project/income-expenditure";
export default {
  mixins: [organization],

  props: {
    projectId: {
      type: String,
    },
    income: {
      type: Number,
    },
    expenditure: {
      type: Number,
    },
    refreshData: {
      type: Function,
    },
  },

  data() {
    return {
      visible: false,
      form: this.$form.createForm(this),
    };
  },

  methods: {
    addText() {
      this.visible = true;
    },
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          const deptId = this.form.getFieldValue("deptId");
          const obj = this.organizationList.find((item) => item.id === deptId);

          addDepartment({
            projectId: this.projectId,
            ...values,
            deptName: obj ? obj.uniqueName : undefined,
            income: Number(
              (this.income * this.form.getFieldValue("ratio") * 0.01).toFixed(2)
            ),
            expenditure: Number(
              (
                this.expenditure *
                this.form.getFieldValue("ratio") *
                0.01
              ).toFixed(2)
            ),
          }).then(() => {
            this.form.resetFields();
            this.cancel();
            this.refreshData();
          });
        }
      });
    },
    cancel() {
      this.visible = false;
    },
  },
};
</script>