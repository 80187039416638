<template>
  <div class="container">
    <div class="header between">
      <div class="title">收支详情</div>
      <div v-if="$getPermission($route.path + '/add')">
        <Add :projectId="id" :refreshData="getList" />
      </div>
    </div>
    <div class="info left">
      <div style="width: 200px">
        <span>合计收入：</span>
        <span class="money" style="color: #1890ff">{{ income }}</span>
        <span>元</span>
      </div>
      <div>
        <span>合计支出：</span>
        <span class="money danger">{{ expenditure }}</span>
        <span>元</span>
      </div>
    </div>
    <a-table
      bordered
      :data-source="list"
      :loading="loading"
      :pagination="false"
    >
      <a-table-column title="实际发生时间" align="center">
        <template slot-scope="text">
          {{ text.operateDate }}
        </template>
      </a-table-column>

      <a-table-column title="金额(元)" align="right">
        <template slot-scope="text">
          <span v-if="text.receivedAmount > 0" style="color: #1890ff">
            {{ text.receivedAmount }}
          </span>
          <span class="danger" v-else>
            {{ text.receivedAmount }}
          </span>
        </template>
      </a-table-column>

      <a-table-column title="费用性质" align="center">
        <template slot-scope="text">
          <DataDictFinder dictType="costNature" :dictValue="text.costNature" />
        </template>
      </a-table-column>

      <a-table-column title="流水来源" align="center">
        <template slot-scope="text">
          {{ text.waterSource }}
        </template>
      </a-table-column>

      <a-table-column title="办理人" align="center">
        <template slot-scope="text">
          <span>
            {{ text.operator }}
          </span>
        </template>
      </a-table-column>

      <a-table-column title="分包性质">
        <template slot-scope="text">
          <a-tag v-if="text.subNature === '0'" color="#f00"
            >设计类-非必要分包</a-tag
          >
          <a-tag v-if="text.subNature === '1'" color="#1890ff"
            >设计类-必要分包</a-tag
          >
          <a-tag v-if="text.subNature === '2'" color="green"
            >总承包类分包</a-tag
          >
        </template>
      </a-table-column>

      <a-table-column title="分包科目">
        <template slot-scope="text">
          <span>
            {{ text.subSubject }}
          </span>
        </template>
      </a-table-column>

      <a-table-column title="备注">
        <template slot-scope="text">
          <span>
            {{ text.remarks }}
          </span>
        </template>
      </a-table-column>

      <a-table-column align="right" width="120px" fixed="right">
        <div style="padding-right: 7px" slot="title">操作</div>
        <template slot-scope="text">
          <a-space>
            <Edit
              v-if="$getPermission($route.path + '/edit')"
              :text="text"
              :projectId="id"
              :refreshData="getList"
            />

            <a
              href="#"
              class="danger"
              v-if="$getPermission($route.path + '/delete')"
              @click.prevent="removeText(text)"
              >删除</a
            >
          </a-space>
        </template>
      </a-table-column>
    </a-table>
  </div>
</template>

<script>
import watermark from "@/mixins/watermark";
import { remove } from "@/api/project/income-expenditure";

import Add from "./add.vue";
import Edit from "./edit.vue";
export default {
  mixins: [watermark],

  props: ["loading", "list", "name", "income", "expenditure", "id", "getList"],

  components: { Add, Edit },

  data() {
    return {
      editingText: {},
      editVisible: false,
    };
  },

  methods: {
    editText(text) {
      this.editingText = text;
      this.editVisible = true;
    },
    removeText(text) {
      const that = this;

      this.$confirm({
        title: "确认要删除这条收支记录吗？",
        onOk() {
          remove({
            id: text.id,
          }).then(() => {
            that.getList();
          });
        },
      });
    },
  },
};
</script>
<style lang="less" scoped>
.container {
  background-color: #fff;
  padding: 8px;

  .header {
    margin-bottom: 8px;
    padding-bottom: 8px;
    border-bottom: 1px solid #f0f0f0;

    .title {
      font-size: 16px;
    }
  }
}

.info {
  margin-bottom: 8px;
  .money {
    padding: 0 4px;
    font-size: 16px;
  }
}
</style>