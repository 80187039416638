<template>
  <div>
    <a href="#" @click.prevent="editText">编辑</a>

    <a-modal
      :visible="visible"
      title="编辑部门汇总"
      @cancel="cancel"
      :footer="null"
    >
      <a-form
        :form="form"
        :colon="false"
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 14 }"
        @submit="handleSubmit"
      >
        <a-form-item label="部门">
          <a-select
            show-search
            :filter-option="$selectFilterOption"
            v-decorator="[
              'deptId',
              {
                initialValue: text.deptId,
                rules: [{ required: true, message: '请选择！' }],
              },
            ]"
            style="width: 100%"
            placeholder=""
          >
            <a-select-option
              v-for="item in organizationList"
              :key="item.id"
              :value="item.id"
              >{{ item.uniqueName }}</a-select-option
            >
          </a-select>
        </a-form-item>

        <a-form-item label="比例">
          <a-input-number
            style="width: 100%"
            :min="0"
            :max="100"
            :formatter="$ratioFormatter"
            v-decorator="[
              'ratio',
              {
                initialValue: text.ratio,
                rules: [{ required: true, message: '请输入！' }],
              },
            ]"
          />
        </a-form-item>

        <a-form-item label="收入汇总">
          {{
            form.getFieldValue("ratio") > 0
              ? (income * (form.getFieldValue("ratio") * 0.01)).toFixed(2) +
                " 元"
              : (income * (text.ratio * 0.01)).toFixed(2) + " 元"
          }}
        </a-form-item>
        <a-form-item label="支出汇总">
          {{
            form.getFieldValue("ratio") > 0
              ? (expenditure * (form.getFieldValue("ratio") * 0.01)).toFixed(
                  2
                ) + " 元"
              : (expenditure * (text.ratio * 0.01)).toFixed(2) + " 元"
          }}
        </a-form-item>

        <div class="right">
          <a-space>
            <a-button @click="cancel">取消</a-button>
            <a-button type="primary" html-type="submit"> 保存 </a-button>
          </a-space>
        </div>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
import organization from "@/mixins/organization";
import { editDepartment } from "@/api/project/income-expenditure";
export default {
  mixins: [organization],
  props: {
    projectId: {
      type: String,
    },
    income: {
      type: Number,
    },
    expenditure: {
      type: Number,
    },
    text: {
      type: Object,
      default: () => ({}),
    },
    refreshData: {
      type: Function,
    },
  },

  data() {
    return {
      form: this.$form.createForm(this),
      user: {},
      visible: false,
    };
  },

  methods: {
    editText() {
      this.visible = true;
    },

    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          editDepartment({
            ...this.text,
            projectId: this.projectId,
            ...values,
          }).then(() => {
            this.form.resetFields();
            this.cancel();
            this.refreshData();
          });
        }
      });
    },
    cancel() {
      this.visible = false;
    },
  },
};
</script>